@media (max-width: 500px) {
    .container {
    padding-left: 10px;
    padding-right: 10px;
    }
}
/*
    ==========
    LOADER
    ==========
*/
.loader-container:not(.active) {
    display: none;
}
.loader-container {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 999999;
}
.loader-container .overlay {
    position: absolute;
    background: black;
    opacity: 0.5;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
}
.lds-ai {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    top: calc(50% - 40px);
    left: calc(50% - 40px);
}
.lds-ai div {
    display: inline-block;
    position: absolute;
    left: 8px;
    width: 16px;
    background: #fff;
    animation: lds-ai 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.lds-ai div:nth-child(1) {
    left: 8px;
    animation-delay: -0.24s;
}
.lds-ai div:nth-child(2) {
    left: 32px;
    animation-delay: -0.12s;
}
.lds-ai div:nth-child(3) {
    left: 56px;
    animation-delay: 0;
}
@keyframes lds-ai {
    0% {
        top: 8px;
        height: 64px;
    }
    50%, 100% {
        top: 24px;
        height: 32px;
    }
}

/*
    ==========
        Toggle
    ==========
*/
input.toggle:checked ~ .dot {
    transform: translateX(150%);
}
